
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { CommonDayPopup } from '@/modules/common/components/common-day-popup';
import type Day from '@/modules/common/types/day.type';
import { ASSESSMENT_TYPES } from '@/modules/common/constants';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterDiLiteService, { ClusterDiLiteServiceS } from '../cluster-di-lite.service';
import DiLiteDayPopupTable from '../components/di-lite/di-lite-day-popup-table.vue';
import ClusterService, { ClusterServiceS } from '../cluster.service';
import ClusterHotelsRatesModel from '../models/cluster-rates.model';

@Component({ components: { CommonDayPopup, DiLiteDayPopupTable } })
export default class DiLiteDayModal extends Vue {
    @Inject(ClusterDiLiteServiceS) clusterDiLiteService!: ClusterDiLiteService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    priceShown: PRICE_SHOWN = this.documentFiltersService.priceShown;

    NO_DATA = ASSESSMENT_TYPES.NO_DATA;
    OUT_OF_RANGE = ASSESSMENT_TYPES.OUT_OF_RANGE;

    get haveStatus() {
        return this.dayData === ASSESSMENT_TYPES.NO_DATA || this.dayData === ASSESSMENT_TYPES.OUT_OF_RANGE;
    }

    get hotel() {
        const hotelId = parseInt(this.$route.params.hotelId, 10);
        return this.clusterService.getHotelData<ClusterHotelsRatesModel>(hotelId);
    }

    get dayData() {
        const day = parseInt(this.$route.params.day, 10) as Day;

        if (!this.hotel || !this.hotel.compsetMain) {
            return ASSESSMENT_TYPES.OUT_OF_RANGE;
        }

        return this.clusterDiLiteService.getDayData(day, this.hotel.compsetMain, this.priceShown) || ASSESSMENT_TYPES.NO_DATA;
    }

    handlePriceTypeChange(p: PRICE_SHOWN) {
        this.priceShown = p;
    }
}
