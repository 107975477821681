
import { Component, Vue, Prop } from 'vue-property-decorator';
import { COLOR_NAMES } from '../../constants';

@Component
export default class PriceRate extends Vue {
    @Prop({ type: String })
    value!: string;

    @Prop({ type: String })
    title!: string;

    @Prop({ type: String, default: null })
    color!: COLOR_NAMES;
}
