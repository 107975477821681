
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { PriceRate } from '@/modules/common/components/common-day-popup';
import { ASSESSMENT_COLORS } from '@/modules/common/constants';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import RatesPriceHistoryCommonService, {
    RatesPriceHistoryCommonServiceS,
} from '@/modules/common/modules/rates-price-history/rates-price-history-common.service';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import LosRestriction from '@/modules/common/components/ui-kit/los-restriction.vue';
import ClusterDiLiteService, { ClusterDiLiteServiceS } from '../../cluster-di-lite.service';
import ClusterService, { ClusterServiceS } from '../../cluster.service';
import type { DayData } from '../../interfaces';
import RatesCompsetMainModel from '../../models/rates-compset-main.model';

@Component({ components: { PriceRate, TogglePrices, LosRestriction } })
export default class DiLiteDayPopupTable extends Vue {
    @Inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(ClusterDiLiteServiceS) private clusterDiLiteService!: ClusterDiLiteService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(HelperServiceS) private helperService!: HelperService;

    @Prop({ type: Object })
    dayData!: DayData;

    @Prop({ type: String })
    priceShown!: PRICE_SHOWN;;

    get rateColor() {
        const { average, highest } = this.dayData;
        const assessment = this.clusterDiLiteService.getAssessment(highest, average);
        return ASSESSMENT_COLORS[assessment];
    }

    diff(index: number) {
        const { average, data } = this.dayData;
        const item = data[index];

        if (item.price === null || item.price === PRICE.NA || item.price === PRICE.SOLD_OUT) {
            return '-';
        }

        return `${this.clusterDiLiteService.getDiff(item.price, average)}%`;
    }

    providerLabel(providerName: string) {
        return this.providersService.getProviderLabel(providerName);
    }

    formatPrice(price: number | null) {
        switch (price) {
            case null:
                return 'No Data';
            case PRICE.SOLD_OUT:
                return 'Sold Out';
            case PRICE.NA:
                return 'N/A';
            default:
                return this.currencySymbol(this.dayData.currency) + price;
        }
    }

    currencySymbol(rawCurrency: string) {
        return this.helperService.currencySymbol(rawCurrency) || rawCurrency;
    }

    handleToggleChange(v: PRICE_SHOWN) {
        this.$emit('priceTypeChange', v);
    }

    handlePriceHistoryOpen() {
        const doc = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(Number(this.$route.params.hotelId));
        const { settings } = this.clusterDiLiteService;

        this.ratesPriceHistoryCommonService.initRatesData(doc, settings);

        const priceHistoryRoute = {
            name: `${this.$route.name}.price-history-popup`,
            params: {
                ...this.$route.params,
                historyDay: this.$route.params.day,
            },
        };

        this.$router.push(priceHistoryRoute);
    }
}
