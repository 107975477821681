
import { Component, Vue, Prop } from 'vue-property-decorator';

import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import DayChanger from '@/modules/common/components/day-changer.vue';
// TODO replace with common scan button
import RatesScanBtn from '@/modules/common/modules/rates/components/rates-scan-btn.vue';
import type Day from '../../types/day.type';

/**
 * * Was tested for All Channels mode.
 * Verify functionality after integrating to other places.
 * Common day popup for any service
 * @prop {{[key: string]: any} | null} [scanBtnProps=null] props passed to scan btn, line with on deman will be hidden if null is passed
 * @prop {number} [width=400] width of the popup
 */
@Component({
    components: {
        ModalWrapper,
        PopupEventsContainer,
        DayChanger,
        RatesScanBtn,
    },
})
export default class CommonDayPopup extends Vue {
    @Prop({ type: Object, default: null })
    scanBtnProps!: { [key: string]: any } | null;

    @Prop({ type: Number, default: 880 })
    width!: number;

    get day() {
        return (+this.$route.params.day || 1) as Day;
    }

    handleScanTrigger() {
        this.$emit('triggerScan', this.day);
    }
}
