
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class LosRestriction extends Vue {
    @Prop({
        type: Number,
        default: 0,
    })
    los!: number;
}
